
/* navagate button */
.swiper-button-next{
    background-image: url("../../assets/images/both/swiper-arrow.png");
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .swiper-button-next::after{
    display: none;
  }
  .swiper-button-prev{
    background-image: url("../../assets/images/both/swiper-arrow.png");
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    transform: rotate(180deg);
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .swiper-button-prev::after{
    display: none;
  }
  .swiper-button-disabled{
    display: none;
  
  }
  
  .swiper-pagination-bullet-active{
    background-color: #B2B1B1;
  }
  .swiper{
   min-height: 500px;
    padding: 2rem 0;
  }
  .swiper-pagination-bullet{
    background-color: #D9D9D9;
  }
  @media (min-width: 448px){
    .swiper{
        min-height: 500px;
         padding: 2rem 35px;
     }
  }
  @media (min-width: 768px) {
   
    .swiper-button-disabled{
        display: block;
    
    }
  }