.App {
  min-height: 100vh;
  scroll-behavior: smooth;
  background-color: #f5f5f5;
}
@media screen and (max-width: 767px) {
  .bg-vector-calendar {
    background-image: url("./assets/images/mobile/calendar-arrow-mobile.png"),
      url("./assets/images/mobile/calendar-arrow-mobile.png"),
      url("./assets/images/mobile/calendar-arrow-mobile.png"),
      url("./assets/images/mobile/calendar-arrow-mobile.png");
    background-repeat: no-repeat;
    background-size: 91% 17.5%, 91% 17%, 91% 17%, 91% 17%;
    background-position: 66% 2.4%, 76% 32.3%, 76% 62.3%, 76% 92.3%;
  }
  .bg-process {
    background-image: url("./assets/images/desktop/application-process-desktop.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right bottom;
  }
}
@media screen and (min-width: 500px) {
  .bg-vector-calendar {
    background: none;
  }
}

@media screen and (min-width: 768px) {
  .bg-banner {
    background-image: url("./assets/images/desktop/banner-desktop.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bg-criteria {
    background-image: url("./assets/images/desktop/criteria-desktop.png");
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 465px 600px;
  }
  .bg-benefit-layer-1 {
    background-image: url("./assets/images/desktop/benefit-desktop.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: left bottom;
  }
}
@media screen and (min-width: 1024px) {
  .bg-vector-calendar {
    background-image: url("./assets/images/desktop/vector-calendar-desktop.png");
    background-repeat: no-repeat;
    background-size: 95% 65%;
    background-position: 35% 17%;
  }
  .bg-process {
    background-image: url("./assets/images/desktop/application-process-desktop.png");
    background-repeat: no-repeat;
    background-size: 454px 432px;
    background-position: 97% 75%;
  }
}
